import { Component } from '@angular/core';

@Component({
  selector: 'bx-club-kit-warning',
  templateUrl: './club-kit-warning.component.html',
  styleUrls: ['./club-kit-warning.component.scss']
})
export class ClubKitWarningComponent  {
  constructor() { }
}
