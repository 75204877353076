import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bx-checkout',
  templateUrl: './checkout-layout.component.html',
  styleUrls: ['./checkout-layout.component.scss']
})
export class CheckoutLayoutComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
