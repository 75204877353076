export const footerNav3 = [
  {
    name: 'Black Card',
    url: '/help-and-info/contact-us'
  },
  {
    name: 'Login',
    url: '/'
  },
  {
    name: 'Create Account',
    url: '/help-and-info/ordering-and-delivery'
  },
  {
    name: 'Amateur Boxer Discount',
    url: '/'
  },
  {
    name: 'Student and Youth Discoint',
    url: '/'
  },
  {
    name: 'NHS Discount',
    url: '/'
  },

];
