import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bx-adverts',
  templateUrl: './adverts.component.html',
  styleUrls: ['./adverts.component.scss']
})
export class AdvertsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
