export const footerNav = [
  {
    name: 'Contact Us',
    url: '/help-and-info/contact-us'
  },
  {
    name: 'FAQs',
    url: ''
  },
  {
    name: 'Track Order',
    url: '/help-and-info/ordering-and-delivery'
  },
  {
    name: 'Support',
    url: ''
  },
  {
    name: 'Returns',
    url: '/help-and-info/size-guide'
  },
  {
    name: 'Shipping and Delivery',
    url: '/help-and-info/size-guide'
  },
  // {
  //   name: 'Care Instructions',
  //   url: '/help-and-info/care-instructions'
  // },
  // {
  //   name: 'See Name Styles',
  //   url: '/help-and-info/name-styles'
  // },
  // {
  //   name: 'About Us',
  //   url: '/help-and-info/about-us'
  // },

  // {
  //   name: 'Terms & Conditions',
  //   url: '/help-and-info/terms-and-conditions'
  // },
  // {
  //   name: 'Privacy Policy',
  //   url: '/help-and-info/privacy-policy'
  // },
  // {
  //   name: 'Cookie Policy',
  //   url: '/help-and-info/cookie-policy'
  // },
  // {
  //   name: 'Feedback',
  //   url: '/feedback'
  // }
];
