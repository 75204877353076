export const footerNav2 = [
  {
    name: 'The Brand',
    url: '/'
  },
  {
    name: 'Find a Way',
    url: '/'
  },
  {
    name: 'Blog',
    url: '/'
  },
  {
    name: 'Boxing',
    url: '/'
  },
  {
    name: 'Careers',
    url: '/'
  },
  {
    name: 'Retail Enquiries',
    url: '/'
  },

];
